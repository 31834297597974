import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {FirebaseRemoteConfigService, WEB_WELCOME_FLOW} from "./firebase-remote-config.service";
import {isPlatformServer} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class AbTestManagerService {

    private abTestKey: string | undefined;
    private abTestValue: string | undefined;
    private isAbTestActive: boolean;
    private initDone: boolean = false;
    private readonly storedValueKey = 'welcome_ab_test'

    constructor(
        private firebaseRemoteConfigService: FirebaseRemoteConfigService,
        @Inject(PLATFORM_ID) private platformId: object) {
    }

    initAbTest = async () => {
        // TODO: fix this
        if (isPlatformServer(this.platformId)) {
            return;
        }

        try {
            this.isAbTestActive = await this.firebaseRemoteConfigService.shouldUseAbTest();
            if (this.isAbTestActive) {
                this.abTestValue = await this.initAbTestValue();
                this.abTestKey = await this.firebaseRemoteConfigService.getConfigValue(WEB_WELCOME_FLOW, 'testKey');
            } else {
                this.abTestValue = undefined;
                this.abTestKey = undefined;
            }
            this.initDone = true;
            return {key: this.abTestKey, value: this.abTestValue};
        } catch (e) {
            console.error(e)
        }
    }

    private initAbTestValue = async () => {
        // check localStorage
        const storedValue = localStorage.getItem(this.storedValueKey);
        if (storedValue) {
            return storedValue;
        } else { // getRandom
            const testValues = await this.firebaseRemoteConfigService.getConfigValue(WEB_WELCOME_FLOW, 'testValues')
            const randomValue = testValues.at(Math.floor(Math.random() * testValues.length))
            localStorage.setItem(this.storedValueKey, randomValue);
            return randomValue;
        }
    }


    getAbTestValues = (): Promise<{ key: string | undefined, value: string | undefined }> | { key: string | undefined, value: string | undefined } => {
        if (!this.initDone) {
            return this.initAbTest();
        } else {
            return {key: this.abTestKey, value: this.abTestValue};
        }
    }


}
