import {EventSender} from "../event-sender.interface";
import {
    init as amplitudeInit,
    identify,
    logEvent,
    revenue,
    Identify,
    Revenue,
    setOptOut,
    setUserId,
    getDeviceId
} from '@amplitude/analytics-browser';
import {environment} from "../../../../environments/environment";
import {NgCookieService} from "../../ng-cookie.service";
import moment from "moment";
import {AbTestManagerService} from "../../ab-test-manager.service";
import {isPlatformBrowser} from "@angular/common";
import {Inject, PLATFORM_ID} from "@angular/core";

export class AmplitudeEventSender implements EventSender {

    initializePromise: Promise<void>

    constructor(
        private optOut: boolean = false,
        private cookieService: NgCookieService,
        private abTestManagerService: AbTestManagerService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        const deviceIdCookieValue = this.cookieService.getCookie('ampDeviceId')
        const deviceId = deviceIdCookieValue == "" ? undefined : deviceIdCookieValue;
        this.initializePromise = amplitudeInit(environment.globals.amplitudeAPIKey, null, {
            deviceId,
            defaultTracking: false
        })
            .promise
            .then(async () => {
                if (deviceId) {
                    this.cookieService.setCookie('ampDeviceId', getDeviceId(), moment().add(1, 'year').format())
                }
                if(isPlatformBrowser(this.platformId)) {
                    await this.setTestUserProperties()
                }
            })

        setOptOut(optOut);
    }

    async init(userProperties: {}): Promise<void> {
        await this.initializePromise
        let identifyObj = new Identify();
        for (let property in userProperties) {
            identifyObj.setOnce(property, userProperties[property]);
        }
        identify(identifyObj)
    }

    async identify(userId: string): Promise<void> {
        await this.initializePromise
        setUserId(userId);
    }

    async log(event: string, data?: any): Promise<void> {
        await this.initializePromise
        logEvent(event, data)
    }

    async logRevenue(plan: string, price: number): Promise<void> {
        await this.initializePromise
        let revenueObj = new Revenue()
            .setProductId(plan)
            .setPrice(price)
            .setQuantity(1);

        revenue(revenueObj);
    }

    async logPageView(properties: {} = {}): Promise<void> {
        await this.initializePromise
        logEvent("PageView", properties)
    }

    private async setTestUserProperties() {
        const abTestValues = await this.abTestManagerService.getAbTestValues();
        if (abTestValues.key && abTestValues.value) {
            let identifyObj = new Identify();
            identifyObj.setOnce(abTestValues.key, abTestValues.value);
            identify(identifyObj)
        }
    }
}