import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {AngularFireRemoteConfig} from '@angular/fire/compat/remote-config';
import {isPlatformServer} from "@angular/common";

export const BYTEPLUS_ENABLED_KEY = "is_byteplus_enabled";
export const TELEPROMPTER_CHANGE_CAMERA_KEY = "teleprompter_camera_impl"
export const BYTEPLUS_CAMERA_VALUE = "BYTEPLUS";
export const DEFAULT_CAMERA_VALUE = "CAMERA_VIEW"
export const WEB_WELCOME_FLOW = "web_welcome_flow"


@Injectable({
    providedIn: 'root'
})
export class FirebaseRemoteConfigService {
    private configData: { [key: string]: any } = {};
    private isConfigFetched: boolean = false;
    readonly teleprompterCameraKeyCookie = 'teleprompter-main-camera';

    constructor(
        private remoteConfig: AngularFireRemoteConfig,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }

    private async fetchConfig(): Promise<void> {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const remoteConfig = this.remoteConfig;
        try {
            await remoteConfig.fetchAndActivate();
            this.isConfigFetched = true;

            const localValue = localStorage.getItem(this.teleprompterCameraKeyCookie);

            // Populate local configData with fetched remote values
            this.configData = {
                [BYTEPLUS_ENABLED_KEY]: await remoteConfig.getBoolean(BYTEPLUS_ENABLED_KEY),
                [TELEPROMPTER_CHANGE_CAMERA_KEY]: localValue ? localValue : await remoteConfig.getString(TELEPROMPTER_CHANGE_CAMERA_KEY),
                [WEB_WELCOME_FLOW]: JSON.parse(await remoteConfig.getString(WEB_WELCOME_FLOW))
            };
        } catch (error) {
            console.error('Error fetching remote config:', error);
            throw error; // Re-throw the error to allow the caller to handle it if needed
        }
    }


    async getConfigValue(key: string, subKey?: string): Promise<any> {
        if (!this.isConfigFetched) {
            await this.fetchConfig();
        }

        if (!subKey) {
            return this.configData[key];
        } else {
            return this.configData[key][subKey];
        }

    }

    async shouldUseAbTest(): Promise<boolean> {
        if (isPlatformServer(this.platformId)) {
            return false;
        }

        if (!this.isConfigFetched) {
            await this.fetchConfig();
        }
        return this.configData[WEB_WELCOME_FLOW]['testKey'] &&
            this.configData[WEB_WELCOME_FLOW]['testValues'];
    }

    async canUseBytePlus(): Promise<boolean> {
        if (isPlatformServer(this.platformId)) {
            return false;
        }

        if (!this.isConfigFetched) {
            await this.fetchConfig();
        }
        return this.configData[BYTEPLUS_ENABLED_KEY] === true
    }

    async shouldUseBytePlus(): Promise<boolean> {
        if (isPlatformServer(this.platformId)) {
            return false;
        }

        if (!this.isConfigFetched) {
            await this.fetchConfig();
        }
        return this.configData[BYTEPLUS_ENABLED_KEY] === true &&
            this.configData[TELEPROMPTER_CHANGE_CAMERA_KEY] === BYTEPLUS_CAMERA_VALUE
    }


    async setTeleprompterCamera(value: boolean) {
        try {
            if (isPlatformServer(this.platformId)) {
                return;
            }

            const stringValue = value ? BYTEPLUS_CAMERA_VALUE : DEFAULT_CAMERA_VALUE;

            if (stringValue === BYTEPLUS_CAMERA_VALUE && this.configData[BYTEPLUS_ENABLED_KEY] === false) {
                return;
            }

            // Set the value in local storage
            localStorage.setItem(this.teleprompterCameraKeyCookie, stringValue);

            // Update local configData with new value
            this.configData[TELEPROMPTER_CHANGE_CAMERA_KEY] = stringValue;

            console.log(`Config value for ${TELEPROMPTER_CHANGE_CAMERA_KEY} set to ${stringValue}`);
        } catch (error) {
            console.error(`Error setting config value for ${TELEPROMPTER_CHANGE_CAMERA_KEY}:`, error);
        }
    }

}
